const certs = [
  {
    id: 1,
    title: "Professional Cloud Architect",
    issuer: "Google Cloud",
    link: "https://www.credly.com/badges/b142d4a5-820b-415d-9f8f-0011c3b47698/public_url",
    imageId1: require("../images/gcp_pca.png"),
    imageId2: require("../images/gcp_pca_2.png"),
  },
  {
    id: 2,
    title: "Professional Data Engineer",
    issuer: "Google Cloud",
    link: "https://www.credly.com/badges/f113e480-b52c-4af5-9930-56ca3708951e/public_url",
    imageId1: require("../images/gcp_pde.png"),
    imageId2: require("../images/gcp_pde_2.png"),
  },
  {
    id: 3,
    title: "Solutions Architect - Associate",
    issuer: "Amazon Web Services",
    link: "https://www.credly.com/badges/a4797af1-6686-4cfa-a99e-26c09d64da59/public_url",
    imageId1: require("../images/aws_sa.png"),
    imageId2: require("../images/aws_sa.png"),
  },
  {
    id: 4,
    title: "Data Engineer Associate",
    issuer: "Databricks",
    link: "https://credentials.databricks.com/0cc261d0-e704-4ea6-8d8a-d4ee0bf95362#acc.6VlWXOjP",
    imageId1: require("../images/db_ade.png"),
    imageId2: require("../images/db_ade_2.png"),
  },
  {
    id: 5,
    title: "Associate Developer for Apache Spark 3.0",
    issuer: "Databricks",
    link: "https://credentials.databricks.com/022d9cea-2b4a-40d5-b982-83b69ae0e058#acc.qYcKzgHL",
    imageId1: require("../images/db_spark.png"),
    imageId2: require("../images/db_spark_2.png"),
  },
  {
    id: 6,
    title: "Associate Cloud Engineer",
    issuer: "Google Cloud",
    link: "https://www.credential.net/74112d3d-0339-44a8-a102-0d60c025d2f2#gs.sfdgvm",
    imageId1: require("../images/gcp.png"),
    imageId2: require("../images/gcp_ace.png"),
  },
  {
    id: 7,
    title: "Artificial Intelligence with Python",
    issuer: "Harvard (CS50)",
    link: "https://certificates.cs50.io/66a220e4-8ffe-4833-8d74-bb6d228463fc.pdf?size=letter",
    imageId1: require("../images/Harvard-Logo.png"),
    imageId2: require("../images/CS50AI.png"),
  },
  {
    id: 8,
    title: "Application Consultant",
    issuer: "SAP Analytics Cloud",
    link: "https://www.credly.com/badges/3929d79c-ded9-4640-8531-bad1ea19e911/public_url",
    imageId1: require("../images/sac.png"),
    imageId2: require("../images/sap_cert.png"),
  },
];

export default certs;
